// import React, { useState, useEffect } from "react";
// import "./StopLoss.css";
import dex from "../images/dex1.gif";
import dex2 from "../images/dex2.gif";

// export default function StopLoss() {
//   const [selected, setSelected] = useState("Market");
//   const [formValues, setFormValues] = useState([
//     { address: "", allocation: "", limitPrice: "", time: "" },
//   ]);
//   const [stopLossAssets, setStopLossAssets] = useState([]);

//   useEffect(() => {
//     // Fetch assets dynamically from an API
//     async function fetchAssets() {
//       try {
//         const response = await fetch("https://api.example.com/assets");
//         const data = await response.json();
//         // Format the response into the required structure
//         const formattedAssets = data.map((asset) => ({
//           asset: asset.name,
//           currentStopLoss: "",
//           stopLossPrice: "",
//           percent: 100,
//         }));
//         setStopLossAssets(formattedAssets);
//       } catch (error) {
//         console.error("Error fetching assets:", error);
//       }
//     }

//     if (selected === "Stop Loss") {
//       fetchAssets();
//     }
//   }, [selected]);

//   const handleMarketChange = (i, e) => {
//     const newFormValues = [...formValues];
//     newFormValues[i][e.target.name] = e.target.value;
//     setFormValues(newFormValues);
//   };

//   const addFormFields = () => {
//     setFormValues([
//       ...formValues,
//       { address: "", allocation: "", limitPrice: "", time: "" },
//     ]);
//   };

//   const removeFormFields = (i) => {
//     const newFormValues = [...formValues];
//     newFormValues.splice(i, 1);
//     setFormValues(newFormValues);
//   };

//   const handleStopLossChange = (index, field, value) => {
//     const updatedAssets = [...stopLossAssets];
//     updatedAssets[index][field] = value;
//     setStopLossAssets(updatedAssets);
//   };

//   const executeStopLoss = (index) => {
//     const asset = stopLossAssets[index];
//     console.log(`Executing stop loss for ${asset.asset}`);
//     console.log(`Current Stop Loss: ${asset.currentStopLoss}`);
//     console.log(`Stop Loss Price: ${asset.stopLossPrice}`);
//     console.log(`Percent: ${asset.percent}%`);
//   };

//   const handleSubmitMarket = (e) => {
//     e.preventDefault();
//     console.log("Market form submitted:", formValues);
//   };

//   return (
//     <div className="container card">
//       <div className="header">
//         <h1 className="mainHeading">Ironglass Trading Dashboard</h1>
//       </div>

//       <div className="buttonRow">
//         <button
//           className={
//             selected === "Market" ? "circleButtonSelected" : "circleButton"
//           }
//           onClick={() => setSelected("Market")}
//         >
//           Market
//         </button>
//         <button
//           className={
//             selected === "Stop Loss" ? "circleButtonSelected" : "circleButton"
//           }
//           onClick={() => setSelected("Stop Loss")}
//         >
//           Stop Loss
//         </button>
//       </div>

//       {selected === "Market" && (
//         <div className="formSection  marketSection">
//           <h3 className="sectionHeading">Market Order</h3>
          // <div className="flex justify-center">
          //   <img className="h-36 " src={dex2} />
          // </div>
//           <div className="row">
//             <span className="blueHeading">Select Asset</span>
//             <span className="blueHeading">Allocation</span>
//             <span className="blueHeading">Limit Price</span>
//             <span className="blueHeading">Time</span>
//           </div>
//           <form onSubmit={handleSubmitMarket}>
//             {formValues.map((element, index) => (
//               <div className="row" key={index}>
//                 <select
//                   name="address"
//                   value={element.address}
//                   onChange={(e) => handleMarketChange(index, e)}
//                 >
//                   <option value="">Select Asset</option>
//                   <option value="asset1">Asset 1</option>
//                   <option value="asset2">Asset 2</option>
//                 </select>
//                 <input
//                   type="text"
//                   name="allocation"
//                   placeholder="Allocation"
//                   value={element.allocation}
//                   onChange={(e) => handleMarketChange(index, e)}
//                 />
//                 <input
//                   type="text"
//                   name="limitPrice"
//                   placeholder="Limit Price"
//                   value={element.limitPrice}
//                   onChange={(e) => handleMarketChange(index, e)}
//                 />
//                 <input
//                   type="text"
//                   name="time"
//                   placeholder="Time"
//                   value={element.time}
//                   onChange={(e) => handleMarketChange(index, e)}
//                 />
//               </div>
//             ))}
//             <div className="actionRow">
//               <button
//                 type="button"
//                 className="addButton"
//                 onClick={addFormFields}
//               >
//                 Add New Asset
//               </button>
//               <button
//                 type="button"
//                 className="removeButton"
//                 onClick={() => removeFormFields(formValues.length - 1)}
//                 disabled={formValues.length <= 1}
//               >
//                 Remove Asset
//               </button>
//               <button type="submit" className="submitButton">
//                 Submit
//               </button>
//             </div>
//           </form>
//         </div>
//       )}

//       {selected === "Stop Loss" && (
//         <div className="">
//           <div className="st-header ">
//             <h1 className="st-mainHeading">Stop Loss</h1>
//             <div className="flex justify-center">
//               <img className="h-36 " src={dex} />
//             </div>
//           </div>
//           <div className="st-headingRow">
//             <span>Asset Name</span>
//             <span>Current Stop Loss</span>
//             <span>Set Price of Stop Loss</span>
//             <span>Percent</span>
//             <span>Action</span>
//           </div>
//           {stopLossAssets.map((asset, index) => (
//             <div className="st-row" key={index}>
//               <span>{asset.asset}</span>
//               <input
//                 type="text"
//                 className="st-input"
//                 placeholder="Current Stop Loss"
//                 value={asset.currentStopLoss}
//                 onChange={(e) =>
//                   handleStopLossChange(index, "currentStopLoss", e.target.value)
//                 }
//               />
//               <input
//                 type="text"
//                 className="st-input"
//                 placeholder="Set Stop Loss Price"
//                 value={asset.stopLossPrice}
//                 onChange={(e) =>
//                   handleStopLossChange(index, "stopLossPrice", e.target.value)
//                 }
//               />
//               <input
//                 type="number"
//                 className="st-input"
//                 placeholder="Percent"
//                 value={asset.percent}
//                 min="1"
//                 max="100"
//                 onChange={(e) =>
//                   handleStopLossChange(index, "percent", e.target.value)
//                 }
//               />
//               <button
//                 className="st-executeButton"
//                 onClick={() => executeStopLoss(index)}
//               >
//                 Execute
//               </button>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }



import React, { useState, useEffect } from "react";
import "./StopLoss.css"; // Ensure you import the CSS file for styling

export default function TradingDashboard() {
  const [formValues, setFormValues] = useState([
    { address: "", allocation: "", limitPrice: "", time: "" },
  ]);
  const [stopLossAssets, setStopLossAssets] = useState([
    { asset: "Asset 1", currentStopLoss: "", stopLossPrice: "", percent: 100 },
    { asset: "Asset 2", currentStopLoss: "", stopLossPrice: "", percent: 100 },
  ]);
  const [selected, setSelected] = useState("Market");
  const [tokensAll, setTokensAll] = useState([]);
  const [chainId, setChainId] = useState("0x1");
  const testUrl = "http://localhost";
  const prodUrl = "https://ironglassinvestment.com";
  const url = testUrl;

  // Fetch tokens for the dropdown
  const getTokenList = async () => {
    let urlPath;
    if (chainId === "0x1") {
      urlPath = `${url}:3004/tokenListAll`;
    } else if (chainId === "0x0") {
      urlPath = `${url}:3004/tokenlist2`;
    }

    if (urlPath) {
      const list = await fetch(urlPath);
      const tokenList = await list.json();
      setTokensAll(tokenList);
    }
  };

  useEffect(() => {
    getTokenList();
  }, [chainId]);

  const handleChange = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const handleStopLossChange = (i, field, value) => {
    const updatedAssets = [...stopLossAssets];
    updatedAssets[i][field] = value;
    setStopLossAssets(updatedAssets);
  };

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      { address: "", allocation: "", limitPrice: "", time: "" },
    ]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitted:", formValues);
    // Add submission logic as needed
  };

  const executeStopLoss = (index) => {
    const asset = stopLossAssets[index];
    console.log("Executing stop loss for:", asset);
    // Add logic for executing stop loss
  };

  const handleTabChange = (tab) => {
    setSelected(tab);
  };

  return (
    <div className="container card bg-black border border-x-yellow-500 border-y-blue-400 p-10">
      <div className="header">
        <h1 className="mainHeading">Ironglass Trading Dashboard</h1>
      </div>

      <div className="buttonRow">
        <button
          className={
            selected === "Market" ? "circleButtonSelected" : "circleButton"
          }
          onClick={() => handleTabChange("Market")}
        >
          Market
        </button>
        <button
          className={
            selected === "Stop Loss" ? "circleButtonSelected" : "circleButton"
          }
          onClick={() => handleTabChange("Stop Loss")}
        >
          Stop Loss
        </button>
      </div>

      {selected === "Market" && (
        <div className="marketSection">
          <h3 className="sectionHeading">Market Order</h3>
          <div className="flex justify-center">
            <img className="h-36 " src={dex2} />
          </div>
          <div className="row">
            <span className="blueHeading">Select Asset</span>
            <span className="blueHeading">Allocation</span>
            <span className="blueHeading">Limit Price</span>
            <span className="blueHeading">Time</span>
          </div>
          <form onSubmit={handleSubmit}>
            {formValues.map((element, index) => (
              <div className="row" key={index}>
                <select
                  name="address"
                  value={element.address}
                  onChange={(e) => handleChange(index, e)}
                  className="assets"
                >
                  <option value="">Select Asset</option>
                  {tokensAll.map((token) => (
                    <option
                      key={token.contract_address}
                      value={token.contract_address}
                    >
                      {token.token_symbol} - {token.price}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  name="allocation"
                  placeholder="Allocation"
                  value={element.allocation}
                  onChange={(e) => handleChange(index, e)}
                />
                <input
                  type="text"
                  name="limitPrice"
                  placeholder="Limit Price"
                  className="text-yellow-300"
                  value={element.limitPrice}
                  onChange={(e) => handleChange(index, e)}
                />
                <input
                  type="text"
                  name="time"
                  placeholder="Time"
                  value={element.time}
                  onChange={(e) => handleChange(index, e)}
                />
              </div>
            ))}
            <div className="actionRow">
              <button
                type="button"
                className="addButton"
                onClick={addFormFields}
              >
                Add New Asset
              </button>
              <button
                type="button"
                className="removeButton"
                onClick={() => removeFormFields(formValues.length - 1)}
                disabled={formValues.length <= 1}
              >
                Remove Asset
              </button>
              <button type="submit" className="submitButton">
                Submit
              </button>
            </div>
          </form>
        </div>
      )}

      {selected === "Stop Loss" && (
        <div className="st-container">
          <h3 className="sectionHeading flex justify-center">Stop Loss</h3>
          <div className="flex justify-center">
            <img className="h-36 " src={dex} />
          </div>
          <div className="st-headingRow">
            <span>Asset Name</span>
            <span>Current Stop Loss</span>
            <span>Set Price of Stop Loss</span>
            <span>Percent</span>
            <span>Action</span>
          </div>
          {stopLossAssets.map((asset, index) => (
            <div className="st-row" key={index}>
              <span>{asset.asset}</span>
              <input
                type="text"
                className="st-input"
                placeholder="Current Stop Loss"
                value={asset.currentStopLoss}
                onChange={(e) =>
                  handleStopLossChange(index, "currentStopLoss", e.target.value)
                }
              />
              <input
                type="text"
                className="st-input"
                placeholder="Set Stop Loss Price"
                value={asset.stopLossPrice}
                onChange={(e) =>
                  handleStopLossChange(index, "stopLossPrice", e.target.value)
                }
              />
              <input
                type="number"
                className="st-input"
                placeholder="Percent"
                value={asset.percent}
                min="1"
                max="100"
                onChange={(e) =>
                  handleStopLossChange(index, "percent", e.target.value)
                }
              />
              <button
                className="st-executeButton"
                onClick={() => executeStopLoss(index)}
              >
                Execute
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
