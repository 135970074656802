import React from "react";
import SetAllocations from "../SetDefiAllocations";
import Myparticle from "../Particless";
import AdminNav from "../navbars/Nav";
import StopLoss from "../StopLoss";
import dex from "../../images/Investor-dex.gif";
import Investment from "../../images/investor.gif";
import MarketOrder from "./MarketOrder";

const Institutional = () => {
  return (
    <div className="section">
      <Myparticle />
  

      <div className="m-5">
        <div className="flex mb-10 justify-center">
          <img src={dex} className="card h-80  border  border-blue-300 " />
        </div>

        <StopLoss />

        <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
          <div className=" bg-black p-2 ">
            <img
              className="w-[100%]  border border-[#34b2fd] h-full card lg:h-auto"
              src={Investment}
              autoPlay
              muted
              loop
              playsInline
            />
          </div>

          <div className="w-full card border bg-black border-[#34b2fd] lg:w-[100%]">
            <p className="text-xl lg:text-xl  rounded-xl  p-2 lg:p-4">
            The Investor tab is designed for individual traders and beginners who seek a straightforward and accessible trading experience. It provides an intuitive interface, seamless wallet integration, and tools for efficiently placing Market and Stop Loss orders. This tab is tailored to empower individuals, offering simplicity and functionality without overwhelming complexity.
            </p>
            <p className="text-xl lg:text-xl  rounded-xl  p-2 lg:p-4 ">
            With user-friendly tools for managing trades and real-time market data, investors can execute trades with confidence and precision. Whether you are a new trader taking your first steps or an experienced investor looking for a simpler solution, the Investor tab provides everything you need to optimize your trading journey and achieve your financial goals effortlessly. It prioritizes accessibility and transparency, making trading simple and secure for everyone.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Institutional;
