
import React, { useState, useEffect } from "react";
import Myparticle from "../Particless";
import AdminNav from "../navbars/Nav";
import AddEscrow from "./addEscrow";
import WalletConnect from "./walletConnect";
import StopLoss from "../StopLoss";
import Investor from "./Investor";

const DashboardDex = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [activeComponent, setActiveComponent] = useState("walletConnect");

  useEffect(() => {
    const checkConnectedAccounts = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
        }
      }
    };
    checkConnectedAccounts();
  }, []);

  const handleNext = () => {
    setActiveComponent("addEscrow");
  };

  return (
    <div className="section">
      <Myparticle />
      <AdminNav />
      <div>
        {activeComponent === "walletConnect" && (
          <WalletConnect onNext={handleNext} />
        )}
      </div>
      <div className="component-container">
        {activeComponent === "addEscrow" && (
          <div className="addEscrowForm">
            {/* <AddEscrow walletAddress={walletAddress} /> */}
            <Investor/>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardDex;