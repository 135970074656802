import React, { useState } from "react";

function MarketOrder() {
  const [formValues, setFormValues] = useState([{ address: "", allocation: "" }]);
  const [tokensAll, setTokensAll] = useState([]);
  const testUrl = "http://localhost";
  const prodUrl = "https://ironglassinvestment.com";
  const url = testUrl; // Use the production or test URL based on your environment

  // Fetch token list from the backend
  const getTokenList = async () => {
    try {
      const response = await fetch(`${url}:3004/tokenListAll`);
      const tokenList = await response.json();
      setTokensAll(tokenList); // Set the token list
    } catch (err) {
      console.error("Error fetching token list:", err);
    }
  };

  React.useEffect(() => {
    getTokenList(); // Fetch token list on component load
  }, []);

  const handleChange = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { address: "", allocation: "" }]);
  };

  const removeFormFields = () => {
    if (formValues.length > 1) {
      const newFormValues = [...formValues];
      newFormValues.pop();
      setFormValues(newFormValues);
    }
  };

  const handleSubmitMarket = async (e) => {
    e.preventDefault();
    const formString = JSON.stringify(formValues);

    try {
      const response = await fetch(`${url}:3004/trade?allocations=${formString}`, {
        method: "POST",
      });
      if (response.status === 200) {
        alert("Market Order Submitted Successfully!");
      } else {
        const error = await response.json();
        alert(error.message || "Error placing market order");
      }
    } catch (err) {
      console.error("Error:", err);
      alert("An unexpected error occurred");
    }
  };

  return (
    <div className="min-h-screen  text-white flex items-center justify-center">
      <div className="bg-[#1a1a1a] card p-6 rounded-lg shadow-lg w-full max-w-3xl">
        {/* Heading */}
        <h1 className="text-3xl font-bold text-center mb-6 text-blue-400">Market</h1>

        {/* Separator */}
        <div className="border-b border-blue-500 mb-6"></div>

        {/* Market Order Form */}
        <form onSubmit={handleSubmitMarket}>
          {formValues.map((element, index) => (
            <div key={index} className="flex items-center justify-between mb-4">
              {/* Asset Dropdown */}
              <div className="flex flex-col w-1/3">
                <label className="text-blue-400 mb-2">Asset</label>
                <select
                  name="address"
                  value={element.address}
                  onChange={(e) => handleChange(index, e)}
                  className="bg-black border border-blue-500 text-blue-400 rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                >
                  <option value="">Select Asset</option>
                  {tokensAll.map((token) => (
                    <option key={token.contract_address} value={token.contract_address}>
                      {token.token_symbol} - ${token.price}
                    </option>
                  ))}
                </select>
              </div>

              {/* Allocation Input */}
              <div className="flex flex-col w-1/3">
                <label className="text-blue-400 mb-2">Allocation (%)</label>
                <input
                  type="number"
                  name="allocation"
                  value={element.allocation}
                  onChange={(e) => handleChange(index, e)}
                  className="bg-black border border-blue-500 text-blue-400 rounded px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  placeholder="Enter %"
                />
              </div>
            </div>
          ))}

          {/* Buttons Row */}
          <div className="flex items-center justify-between mt-6">
            {/* Add and Remove Buttons */}
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={addFormFields}
                className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-400 transition"
              >
                ADD
              </button>
              <button
                type="button"
                onClick={removeFormFields}
                className="bg-red-500 text-white py-2 px-6 rounded hover:bg-red-400 transition"
              >
                REMOVE
              </button>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-green-500 text-white py-2 px-10 rounded hover:bg-green-400 transition"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MarketOrder;
