import React,{useState, useEffect,useContext} from 'react';
import logo from '../../images/igi.png'
import LoginContext from '../../contexts/LoginContext';
import {Link} from "react-router-dom";
import { Sling as Hamburger } from 'hamburger-react';


function NavbarAuth(){
    const {isOpen2,setOpen2} = useContext(LoginContext);
    const {loginpage, setloginpage} = useContext(LoginContext);
    const [navbg,setNavbg] = useState(false)

    useEffect(() => {
    }, [loginpage]);

    // function for the navbar to darken on scroll
    const changeNav = ()=>{
      if(window.scrollY >= 80){
        setNavbg(true)
      }else{
        setNavbg(false)
      }
    }

    window.addEventListener('scroll',changeNav)


    return (

      <div className={navbg ? "navbar bg-base-100 navbar-auth active lg:justify-between" : "navbar bg-base-100 navbar-auth lg:justify-between" }>
        <div className="p-7 w-screen justify-between">
          <Link to="/">
            <img src={logo} alt="..."className="w-32" />
          </Link>
          <ul className="menu menu-horizontal p-0 hidden lg:flex">
            <li><Link to="/" className='btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron'>Home</Link></li>
            <li><Link to="/LearnMore" className='btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron'>Learn More</Link></li>
            <li><Link to="/Login" className='btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron'>Login</Link></li>
          </ul>
          <div className="lg:hidden text-blue2">
          <Hamburger toggled={isOpen2} toggle={setOpen2}/>
          </div>
        </div>
      </div>
    )
}
export default NavbarAuth