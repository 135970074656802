import React from "react";
import { useNavigate } from "react-router-dom";
import Myparticle from "../Particless";
import AdminNav from "../navbars/Nav";
import dex from "../../images/DEX.gif"

const DexTabs = () => {
  const navigate = useNavigate(); // Use React Router for navigation

  return (
<div className="section ">
<Myparticle />
<AdminNav />


    <div className="min-h-screen   flex flex-col items-center">
    
      <div className="mt-6 card border border-blue-500 ">
        <img
          src={dex} // Replace with your actual image URL
          alt="Dex"
          className="h-72 w-auto"
        />
        {/* <h1 className="text-4xl font-bold mt-10 text-blue-600">Welcome to DEX</h1> */}
      </div>
        <p className="text-lg mt-2 text-blue-300">By <span className="text-yellow-400">Ironglass</span> </p>

      {/* Blocks Section */}

      <div className="card flex">

      
      <div className="flex flex-wrap  justify-center mt-10 space-x-6">
        {/* Institutional Block */}
        <div className=" bg-black border border-blue-500 border-b-yellow-400  border-r-yellow-400 shadow-md rounded-lg p-6 w-[31rem] text-center">
          <h2 className="text-3xl font-semibold text-yellow-400">Institutional</h2>
          <p className="text-gray-200 my-8 text-xl ">
          "Institutional trading provides tailored strategies and advanced tools for high-volume traders and organizations to maximize market efficiency"
          </p>
          <button
            onClick={() => navigate("/Institutional")} // Route to Institutional Component
            className="mt-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500"
          >
            Go to Institutional
          </button>
        </div>

        {/* Inverter Block */}
        <div className="bg-black border border-blue-500 border-b-yellow-400  border-l-yellow-400 shadow-md rounded-lg p-6 w-[31rem] text-center">
          <h2 className="text-3xl font-semibold   text-yellow-400">Investor</h2>
          <p className="text-gray-200 my-8 text-xl ">
          "Investor trading simplifies access to decentralized markets, enabling seamless wallet integration and optimized trading for individual investors"
          </p>
          <button
            onClick={() => navigate("/Investor")} // Route to Inverter Component
            className="mt-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500"
          >
            Go to Investor
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  );
};

export default DexTabs;
