



import React, { useState, useEffect } from "react";
import Myparticle from "../Particless";
import wallet from "../../images/Wallet.gif";
import "./wallet.css"; // Make sure to import the CSS file

const WalletConnect = ({ onNext }) => {
  const [walletAddress, setWalletAddress] = useState("");

  useEffect(() => {
    const checkConnectedAccounts = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
        }
      }
    };
    checkConnectedAccounts();
  }, []);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWalletAddress(accounts[0]);
      } catch (error) {
        console.error("User denied account access or there was an error:", error);
      }
    } else {
      alert("MetaMask is not installed. Please install it to use this feature.");
    }
  };

  const handleNext = () => {
    onNext(); // Call the onNext function passed as a prop to move to the next step
  };

  return (
    <div>
      <Myparticle />
      <div className="wallet-connect-container card ">
        <div className="wallet-connect-card">
          <div className="wallet-connect-content">
            <h2 className="wallet-connect-title">Connect to Your Wallet</h2>
            <p className="wallet-connect-description">
              Securely connect your wallet to Ironglass Investment and make safe
              transactions with ease. Manage your crypto assets and investments
              securely with IGI.
            </p>
            <div>
              {!walletAddress ? (
                <button onClick={connectWallet} className="wallet-connect-button">
                  Connect Wallet
                </button>
              ) : (
                <button onClick={handleNext} className="wallet-connect-button">
                  Next <span>→</span>
                </button>
              )}
            </div>
          </div>
          <div className="wallet-connect-image-container">
            <img
              className="wallet-connect-image"
              src={wallet}
              alt="Ironglass Wallet Illustration"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletConnect;