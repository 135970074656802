import React, { useState, useEffect, useRef } from "react";
import fetch from "node-fetch";
//import { ethers } from "ethers";
//import { hover } from '@testing-library/user-event/dist/hover';
function SetAllocations() {
  const [formValues, setFormValues] = useState([
    { address: "", allocation: "" },
    { address: "", allocation: "" },
    { address: "", allocation: "" },
    { address: "", allocation: "" },
    { address: "", allocation: "" },
  ]);
  //const [formValues2, setFormValues2] = useState([{ address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", allocation: ""}])
  const [tokensAll, setTokensAll] = useState([]);
  const [selected, setSelected] = useState("Market");
  const [chainId, setChainId] = useState("0x1");
  const testUrl = "http://localhost";
  const prodUrl = "https://ironglassinvestment.com";
  let url = testUrl;

  const buyRef = useRef(null);
  const sellRef = useRef(null);

  const tabHandler = (e) => {
    if (e.target.class !== sellRef.current.class) {
      e.target.class = "tab tab--active";
      sellRef.current.class = "tab tab--inactive";
      //setIsBuy(false)
    } else {
      e.target.class = "tab tab--active";
      buyRef.current.class = "tab tab--inactive";
      //setIsBuy(true)
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let handleChange2 = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { address: "", allocation: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmitMarket = async (event) => {
    event.preventDefault();

    if (chainId === "0xa4b1") {
      let formSting = JSON.stringify(formValues);
      let tx = await fetch(`${url}:3004/trade?allocations=${formSting}`);
      if (tx.status === 200) {
        alert("Success");
      } else {
        let reason = await tx.json();
        let err = reason.error;
        alert(err);
      }
    }
    if (chainId === "0x0") {
      let formSting = JSON.stringify(formValues);
      let response = await fetch(
        `${url}:3004/coinbaseMarket?allocations=${formSting}`
      );
      if (response.status === 200) {
        alert("Success");
      } else {
        let reason = await response.json();
        //let err = reason.error
        alert(reason);
      }
    }
  };

  let handleSubmitLimit = async (event) => {
    event.preventDefault();
    let formSting = JSON.stringify(formValues);
    let tx = await fetch(`${url}:3004/Limit?allocations=${formSting}`);
    console.log(tx.status);
    if (tx.status === 200) {
      alert("Success");
    } else {
      let reason = await tx.json();
      let err = reason.error;
      alert(err);
    }
  };

  //Function to handle network changes
  const networkHandler = async (e) => {
    setChainId(e.target.value);
  };

  //Function to handle network changes
  const orderHandler = async (e) => {
    setSelected(e.target.value);
  };

  // Function to token list
  async function getTokenList() {
    let list, tokenList, urlPath;

    // Determine the correct URL path based on chainId
    if (chainId === "0x1") {
      urlPath = `${url}:3004/tokenListAll`;
    } else if (chainId === "0x0") {
      urlPath = `${url}:3004/tokenlist2`;
    }

    if (urlPath) {
      list = await fetch(urlPath);
      tokenList = await list.json();
      console.log(tokenList);
      setTokensAll(tokenList);

      // Get all dropdown elements from DOM
      let dropdowns = document.getElementsByClassName("assets");

      // Clear previous options
      for (let i = 0; i < dropdowns.length; i++) {
        dropdowns[i].options.length = 0; // Clears the current dropdown options
      }

      // Add new options for each dropdown
      for (let i = 0; i < tokenList.length; i++) {
        let optionText = `${tokenList[i].token_symbol} - ${tokenList[i].price}`;
        let optionValue;
        if (chainId === "0xa4b1") {
          optionValue = tokenList[i].contract_address;
        } else if (chainId === "0x0") {
          optionValue = tokenList[i].token_symbol;
        }
        for (let j = 0; j < dropdowns.length; j++) {
          dropdowns[j].options[dropdowns[j].options.length] = new Option(
            optionText,
            optionValue
          );
        }
      }
    }
  }

  async function GetCurrentAssets() {
    let urlPath = `${url}:3004/GetCurrentAssets`;
    let currentAssets = await fetch(urlPath);
    currentAssets = await currentAssets.json();
    console.log(currentAssets);
  }

  useEffect(() => {
    getTokenList();
    GetCurrentAssets()
  }, [chainId, selected]);

  useEffect(() => {
    //var dropdown = document.getElementById("assets");
    var dropdown = document.getElementsByClassName("assets");

    //Loop through the array
    for (let w = 0; w < dropdown.length; w++) {
      for (let i = 0; i < tokensAll.length; ++i) {
        dropdown[w].options[dropdown[w].options.length] = new Option(
          tokensAll[i].token_contract,
          tokensAll[i].contract_address
        );
      }
    }
  }, [formValues]);

  return (
    <div style={styles.container}>
      <div style={styles.buttonRow}>
        {selected === "Market" ? (
          <button className="circleButtonSelected">Market</button>
        ) : (
          <button
            className="circleButton"
            value="Market"
            onClick={orderHandler}
          >
            Market
          </button>
        )}
        {selected === "Limit" ? (
          <button className="circleButtonSelected" value="Limit">
            STOP LIMIT
          </button>
        ) : (
          <button className="circleButton" value="Limit" onClick={orderHandler}>
            {" "}
            STOP LIMIT
          </button>
        )}
        {selected === "TWAP" ? (
          <button className="circleButtonSelected">TWAP</button>
        ) : (
          <button className="circleButton" value="TWAP" onClick={orderHandler}>
            TWAP
          </button>
        )}
        {selected === "BLOCK" ? (
          <button className="circleButtonSelected">BLOCK</button>
        ) : (
          <button className="circleButton" value="BLOCK" onClick={orderHandler}>
            BLOCK
          </button>
        )}
        {selected === "VWAP" ? (
          <button className="circleButtonSelected">VWAP</button>
        ) : (
          <button className="circleButton" value="VWAP" onClick={orderHandler}>
            VWAP
          </button>
        )}
      </div>
      <div className="flex justify-center text-blue2">
        <h1>CHAIN</h1>
      </div>
      <br />
      <div className="flex justify-center text-blue2">
        <select name="address" value={chainId} onChange={networkHandler}>
          {/* <option value="0x1">Ethereum</option> */}
          <option value="0xa4b1">Arbitrum</option>
          <option value="0x0">Coinbase</option>
          <option value="0x1">Ethereum</option>
        </select>
      </div>
      <br />
      <div style={styles.separator}></div>
      <div className="flex flex-row min-w-full place-content-center">
        <div className="flex flex-row min-w-full place-content-center">
          <div className="card block text-white mb-9 min-h-screen">
            {selected === "Market" ? (
              <form className="assetform" onSubmit={handleSubmitMarket}>
                {formValues.map((element, index) => (
                  <div className="text-blue2" key={index}>
                    <label>Asset</label>
                    <select
                      name="address"
                      value={element.address}
                      onChange={(e) => handleChange(index, e)}
                      className="assets"
                    ></select>
                    <label>Allocation</label>
                    <input
                      type="text"
                      name="allocation"
                      value={element.allocation}
                      onChange={(e) => handleChange2(index, e)}
                    />
                    {index ? (
                      <button
                        type="button"
                        className="button remove"
                        onClick={() => removeFormFields(index)}
                      >
                        Remove
                      </button>
                    ) : null}
                  </div>
                ))}
                <div style={styles.actionRow}>
                  <button
                    className="btn text-black blue2 orbitron"
                    type="button"
                    onClick={() => addFormFields()}
                  >
                    Add
                  </button>
                  <button
                    className="btn text-black blue2 orbitron"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <div></div>
            )}
            {selected === "Limit" ? (
              <form className="assetform" onSubmit={handleSubmitLimit}>
                {formValues.map((element, index) => (
                  <div className="text-blue2" key={index}>
                    <input
                      class="tab tab--active"
                      useRef={sellRef}
                      type="button"
                      value="Sell"
                    />
                    {/* <input class='tab tab--inactive' onClick={tabHandler} useRef={buyRef}type="button" value="Buy"/>  */}
                    <select
                      name="address"
                      value={element.address}
                      onChange={(e) => handleChange(index, e)}
                      className="assets"
                    ></select>
                    <label>Once price goes drops to</label>
                    <input
                      type="text"
                      name="allocation"
                      value={element.allocation}
                      onChange={(e) => handleChange2(index, e)}
                    />
                    <label>Or less</label>
                    {index ? (
                      <button
                        type="button"
                        className="button remove"
                        onClick={() => removeFormFields(index)}
                      >
                        Remove
                      </button>
                    ) : null}
                  </div>
                ))}
                <div style={styles.actionRow}>
                  <button
                    className="btn text-black blue2 orbitron"
                    type="button"
                    onClick={() => addFormFields()}
                  >
                    Add
                  </button>
                  <button
                    className="btn text-black blue2 orbitron"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: "40%",
    height: "100%",
    backgroundColor: "#000",
    opacity: 0.8,
    color: "#fff",
    padding: "20px",
    borderRadius: "10px",
  },
  buttonRow: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "10px",
  },
  circleButton: {
    backgroundColor: "#000",
    color: "#fff",
    border: "solid",
    borderColor: "#0380ca",
    cursor: "pointer",
  },
  separator: {
    height: "5px",
    backgroundColor: "#34b2fd",
    marginBottom: "10px",
  },
  assetRow: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: "10px",
  },
  dropdown: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: "10px",
  },
  textInput: {
    flex: "2",
    marginRight: "5px",
  },
  removeButton: {
    backgroundColor: "red",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  },
  actionRow: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  actionButton: {
    backgroundColor: "#fff",
    color: "#000",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
  },
};

export default SetAllocations;
