import React from "react";
import SetAllocations from "../SetDefiAllocations";
import Myparticle from "../Particless";
import AdminNav from "../navbars/Nav";
import StopLoss from "../StopLoss";
import dex from "../../images/Institutional-dex.gif";
import Investment from "../../images/institutional.gif";
import MarketOrder from "./MarketOrder";

const Institutional = () => {
  return (
    <div className="section">
      <Myparticle />
      <AdminNav />

      <div className=" m-5">
        <div className="flex mb-10 justify-center">
          <img src={dex} className="card h-80  border  border-blue-300 " />
        </div>

        <StopLoss />

        <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
          <div className=" bg-black p-2 ">
            <img
              className="w-[100%]  border border-[#34b2fd] h-full card lg:h-auto"
              src={Investment}
              autoPlay
              muted
              loop
              playsInline
            />
          </div>

          <div className="w-full card border bg-black border-[#34b2fd] lg:w-[100%]">
            <p className="text-xl lg:text-xl  rounded-xl  p-2 lg:p-4">
            The Institutional tab is designed for high-volume traders and organizations seeking advanced trading tools and strategies. It provides access to in-depth market analytics, precision tools for placing Stop Loss orders, and seamless execution of large trades. This tab is tailored for professionals who require a feature-rich trading environment to maximize efficiency and optimize results
            </p>
            <p className="text-xl lg:text-xl  rounded-xl  p-2 lg:p-4 ">
            With precision tools for setting Stop Loss orders, institutional traders can mitigate risks effectively and execute trades seamlessly, even in volatile market conditions. For professionals who require a robust and feature-rich trading environment, the Institutional tab offers everything they need to maximize their results and gain a competitive edge in the market. Additionally, it ensures secure and scalable trading operations for enterprises managing significant volumes.
            </p>
          </div>
        </div>
      </div>

      {/* <MarketOrder/> */}
      {/* <StopLoss/>/ */}
    </div>
  );
};

export default Institutional;
