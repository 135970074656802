import Loader from "./Loader";
import SetDefiAllocations from "./SetDefiAllocations";
import SetAllocations from "./SetAllocations";
import Ex4 from './Ex4'
import React,{useState, useEffect} from 'react';
import { initializeApp } from 'firebase/app';
import { doc, getDoc} from "firebase/firestore"; 
import { getFirestore } from "firebase/firestore";



function AdminDashboard() {
    const [loading,setLoading] = useState(true);
    const [historicalData, setHistoricalData] = useState(null);
    //const [historicalGrowthData, setHistoricalGrowthData] = useState(null);
    const [totalAum, settotalAum] = useState('');
    const [totalInvested, settotalInvested] = useState('');
    const [totalAumGrowth, settotalAumGrowth] = useState('');
    const [peakAum, setPeakAum] = useState('');
    const [peakAumDate, setPeakAumDate] = useState('');

    //Firebase DB credentials
    const firebaseConfig = {
        apiKey: "AIzaSyCFA3Sa14Uokttej7rC7VFGSIePenYB6ac",
        projectId: "igi2-8ba54",
        storageBucket: "gs://igi2-8ba54.appspot.com",
    };

    async function getData(){
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        let historical 
        //let historicalGrowth
        const docSnap = await getDoc(doc(db, 'aum', "sBJggFcLPsHKEklNdik7"));
        const docSnap1 = await getDoc(doc(db, 'aum', "sBJggFcLPsHKEklNdik8"));
        const docSnap2 = await getDoc(doc(db, 'aum', "sBJggFcLPsHKEklNdik9"));
        const docSnap3 = await getDoc(doc(db, 'aum', "sBJggFcLPsHKEklNdik10"));
        const docSnap4 = await getDoc(doc(db, 'aum', "sBJggFcLPsHKEklNdik11"));
        const docSnap5 = await getDoc(doc(db, 'aum', "sBJggFcLPsHKEklNdik12"));
        //console.log(docSnap.data().historical)
        historical = docSnap.data().historical
        //historical.push(docSnap.data().historical)
        let historical1 = docSnap1.data().historical
        for(let i=0; i< historical1.length; i++){
            historical.push(historical1[i])
        }
        let historical2 = docSnap2.data().historical
        for(let i=0; i< historical2.length; i++){
            historical.push(historical2[i])
        }
        let historical3 = docSnap3.data().historical
        for(let i=0; i< historical3.length; i++){
            historical.push(historical3[i])
        }
        let historical4 = docSnap4.data().historical
        for(let i=0; i< historical4.length; i++){
            historical.push(historical4[i])
        }
        let historical5 = docSnap5.data().historical
        for(let i=0; i< historical5.length; i++){
            historical.push(historical5[i])
        }
        //historicalGrowth = docSnap.data().historicalGrowth
        // let historicalGrowth1 = docSnap1.data().historicalGrowth
        // for(let i=0; i< historicalGrowth1.length; i++){
        //     historicalGrowth.push(historicalGrowth1[i])
        // }
        // let historicalGrowth2 = docSnap2.data().historicalGrowth
        // for(let i=0; i< historicalGrowth2.length; i++){
        //     historicalGrowth.push(historicalGrowth2[i])
        // }
        let totalAum = docSnap.data().currentAum.toFixed(2)
        let totalInvested = docSnap5.data().totalInvested
        let totalGrowth = Number((((totalAum - totalInvested) / totalInvested) * 100).toFixed(2))
        //let peakAum  = docSnap.data().aumPeak.Amount.toFixed(2)
        console.log(docSnap.data())
        setHistoricalData(historical)
        //setHistoricalGrowthData(historicalGrowth)
        settotalAum(totalAum)
        //setPeakAum(peakAum)
        //setPeakAumDate(docSnap.data().aumPeak.Date)
        settotalInvested(Number(docSnap4.data().totalInvested.toFixed(2)))
        settotalAumGrowth(totalGrowth)
        setLoading(false)
    }

    useEffect(() => {
        getData()
      }, []);

    return(
        <div className="card block text-white mb-9 min-h-screen">
            {historicalData ? 
                (
                <Ex4 historicalData={historicalData}/>
                ) : 
                <div className='flex justify-center mt-40'>
                    < Loader loading={loading}/>
                </div>
            }

            <div className="statscards-container">
                {totalInvested && (
                    <div className='statscards text-center'>
                        <h6 className='m-5 orbitron'>Total Invested</h6>
                        <h1 className='text-[#10b981] m-5 orbitron'>$ {totalInvested}</h1>
                    </div>
                )}

                {totalAum && (
                    <div className='statscards text-center'>
                        <h6 className='m-5 orbitron'>Current AUM</h6>
                        <h1 className='text-[#10b981] m-5 orbitron'>$ {totalAum} </h1>
                    </div>
                )}

                {peakAum && (
                    <div className='statscards text-center'>
                        <h6 className='m-5 orbitron'>Peak AUM</h6>
                        <h1 className='text-[#10b981] m-5 orbitron'>$ {peakAum}</h1>
                    </div>
                )}

                {peakAumDate && (
                    <div className='statscards text-center'>
                        <h6 className='m-5 orbitron'>Peak AUM Date/Time</h6>
                        <h1 className='text-[#10b981] m-5 orbitron'> {peakAumDate}</h1>
                    </div>
                )}
                {totalAumGrowth && (
                    <div className='statscards text-center'>
                        <h6 className='m-5 orbitron'>Total AUM growth</h6>
                        <h1 className='text-[#10b981] m-5 orbitron'>{totalAumGrowth} %</h1>
                    </div>
                )}
            </div>

            <div className='flex justify-center mt-20'>
                    {/* <SetDefiAllocations/> */}
                    <SetAllocations/>
            </div>
            {/* {historicalGrowthData ? 
                (
                 <div className='flex justify-center'>
                    <Ex5 historicalData={historicalGrowthData}/>
                  </div>
                ) : 
                <div className='flex justify-center'>
                    < Loader loading={loading}/>
                </div>
            } */}
        </div>
    )
}

export default AdminDashboard;