
export const APP_STATIC_PATH = {
  Main: '',
  Dashboard: '/dashboard',
  Calendly: '/calendly',
  Members: '/members',
  Profile: '/profile',
  Events: '/events',
  Signup: '/signup',
  Login: '/Login',
  LearnMore: 'LearnMore',
  Dex:'Dex',
  Investor:'Investor',
  Institutional :'Institutional'
};
